import React from "react"
import menuStyles from "./menu.module.css"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

//Want to have context dependent bits here? Or do I need to create an alternative menu for each page?
//Could do "If URl is XXX"
//Could do something in the gatsby compile
//Could do something int he React framework






const Menu = () => {
	
	const data = useStaticQuery(graphql`
		query menuImages {
			change_logo: file(relativePath: {eq: "change_org.jpg"}) {
				id
				childImageSharp {
					fixed (width: 52) {
						...GatsbyImageSharpFixed
					}
				}
			},
			go_fund_logo: file(relativePath: {eq: "go_fund_me.jpg"}) {
				id
				childImageSharp {
					fixed (width: 52) {
						...GatsbyImageSharpFixed
					}
				}
			},
			coworker_logo: file(relativePath: {eq: "coworker_logo.png"}) {
				id
				childImageSharp {
					fixed (width: 52) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	
	return (
		<div className={menuStyles.menu}>
		
			<div className={menuStyles.menuMarker}>
				<h1>Menu</h1>
			</div>
		
			<div className={menuStyles.navigation}>
				<Link to="/">
					<div className={menuStyles.link}> Home </div>
				</Link>
				<Link to="/press/">
					<div className={menuStyles.link}> Press Coverage </div>
				</Link>
				<Link to="/blog/">
					<div className={menuStyles.link}> Activity Log </div>
				</Link>
				
				{ /*}
				<Link to="/portraits/">
					<div className={menuStyles.link}> Worker Portraits</div>
				</Link>
				*/}
				<Link to="/contact/">
					<div className={menuStyles.link}> Get in Touch </div>
				</Link>
			</div>
		
			<div className={menuStyles.riderBox}>
				<div className={menuStyles.riderItem}>
					<a href="https://www.change.org/p/market-of-choice-support-black-lives-matter-masks-at-market-of-choice">
						<Img fixed={data.change_logo.childImageSharp.fixed} />
						<p>Change.org Petition</p>
					</a>
				</div>
				
				<div className={menuStyles.riderItem}>
					<a href="https://www.gofundme.com/f/market-of-choice-strike-fund?utm_source=customer&utm_medium=sms&utm_campaign=p_cp+share-sheet">
						<Img fixed={data.go_fund_logo.childImageSharp.fixed}/>
						<p>Our GoFundMe</p>
					</a>
				</div>
			
				<div className={menuStyles.riderItem}>
					<a href="https://www.coworker.org/petitions/moc-workers-united-for-hazard-pay-2">
						<Img fixed={data.coworker_logo.childImageSharp.fixed}/>
						<p>Hazard Pay Petition</p>
					</a>
				</div>
			</div>
		
			<div className={menuStyles.info}>
				IN SOLIDARITY SINCE 2020
			</div>
			
			<div className={menuStyles.line}></div>
			
			<div className={menuStyles.bottomBorder}></div>
		</div>
		
  )
}

export default Menu

