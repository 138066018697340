import React from "react"
import containerStyles from "./container.module.css"
import Top from "../components/top.js"
import Menu from "../components/menu.js"
import Chunk from "../components/chunk.js"
{//RGB(118,159,39) MOC's own color
}

export default ({children}) => (
  <div className={containerStyles.container}>
    <Top/>
    <Chunk>
        {children}
    </Chunk>
    <Menu/>
  </div>
)