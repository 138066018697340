import React from "react"
import topStyles from "./top.module.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Top = () => {
	const topData = useStaticQuery(graphql`
		query topImates {
			blm_pin: file(relativePath: {eq: "blm_pin.png"}) {
				id
				childImageSharp {
					fixed (width: 50) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)
	
	return (
		<div className={topStyles.top}>
			<h1 className={topStyles.first}>
				Market of Choice Workers United
			</h1>
			<h1 className={topStyles.second} >
				<span className={topStyles.red}>
					<Img className={topStyles.blmPin} fixed={topData.blm_pin.childImageSharp.fixed}/>
				</span>
			</h1>
			<div className={topStyles.line_first}></div>
			<div className={topStyles.line_second}></div>
		</div>
  )
}

export default Top
/*
 * We got: 1) Work, 2) about me 3) Resume  4)contact info
 * Could do stuff like subentries in the work list?
 * 
 * Conrad is doing work
 *   with Python
 * Contact Conrad | Conrad's Contact Information
 * Work and Experiences | Resume | CV | Statement of purpose
 * 
 * 
 * conrad is doing... work
 * a collection of some... projects
 * for when you want to know more... about me
 * I'd love to tell you more... about me
 * how to get in... contact
 * take a look at my... resume
 */